/** React/Utils */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

/** Material UI */
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CreateReturnIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InfoIcon from '@material-ui/icons/Info';
import ExchangeIcon from '@material-ui/icons/Shuffle';
import InspectIcon from '@material-ui/icons/Search';
import ModifyIcon from '@material-ui/icons/LocalOffer';
import ShippingIcon from '@material-ui/icons/LocalShipping';

/** Local */
import { DialogTypes } from '../../../constants/dialog.const';
import Geo from '../../../constants/geos.const';
import {
  EmeaPartnersAllowingInspections,
  EmeaPartnersMasqueradingAsGeos,
  EmeaPartnersNotAllowingCancelOnReturn,
  Partners,
} from '../../../constants/origin.const.js';
import {
  CreateReturn,
  CancelOrders,
  InspectReturn,
  ReinspectReturn,
  ModifyPrice,
  DiscountShipping,
  ExchangeProduct,
  ReturnAndCancelO2O,
} from '../../../constants/permissions.const';
import { actions as dialogActions } from '../../../store/actions/dialogActions';
import { DialogContext } from '../../../store/contexts/dialogContext';
import { OrderContext } from '../../../store/contexts/orderContext';
import HasPermission from '../../shared/hasPermission';
import DialogContainer from './../dialog';
import translations from './topBar.i18n';
import TopBarContainer from './topBarContainer';
import { getEmeaPartner, isJdSportsUkOrder } from '../../../utils/order';
import useMemoTranslations from '../../../hooks/useMemoTranslations';
import useHasPermission from '../../../hooks/useHasPermission';
import { isStoreOrderReturn } from '../../../utils/order';

/**
 * TopBar component housing order specific actions and controls for the InfoBar
 */
export default function TopBar({ handleInfoDrawer, infoDrawerOpen }) {
  const classes = useStyles();
  const [orderDetail] = useContext(OrderContext);
  const {
    omsRegionReference: region,
    channel,
    omoboFlags,
    orderType,
    csOrderSummary,
  } = orderDetail;
  const isDSIS = omoboFlags?.isDSIS;
  // order modifications are not allowed for GS orders
  const isGlobalStoreOrder = region === Geo.NIKEGS || region === Geo.NIKEXA;
  const isBopisOrderForAustralia = orderDetail.omoboFlags?.isBOPIS && region === Geo.AUSTRALIA;

  // boolean flag indicating if parent sales order of a return order is a store order
  let isOrderStoreOrderReturn = orderType === 'RETURN_ORDER' && isStoreOrderReturn(csOrderSummary);

  const [, dialogDispatch] = useContext(DialogContext);
  const {
    CREATE_RETURN,
    CANCEL_ITEMS,
    ORDER_SUMMARY,
    EXCHANGE,
    MODIFY,
    DISCOUNT_SHIPPING,
    INSPECT,
    REINSPECT,
  } = useMemoTranslations(translations);
  const { CHINA, KOREA, JAPAN } = Geo;

  const { hasPermission } = useHasPermission();

  const handleOpenDiscountShippingDialog = () => {
    dialogDispatch(dialogActions.open(DialogTypes.DISCOUNT_SHIPPING));
  };

  const handleOpenCancelDialog = () => {
    dialogDispatch(dialogActions.open(DialogTypes.CANCEL));
  };

  const handleOpenInspectDialog = () => {
    dialogDispatch(dialogActions.open(DialogTypes.INSPECT));
  };

  const handleOpenReturnDialog = () => {
    dialogDispatch(dialogActions.open(DialogTypes.RETURN));
  };

  const handleModifyPriceDialog = () => {
    dialogDispatch(dialogActions.open(DialogTypes.MODIFY_PRICE));
  };

  const handleExchangeDialog = () => {
    dialogDispatch(dialogActions.open(DialogTypes.EXCHANGE));
  };

  const emeaPartner = getEmeaPartner(orderDetail);
  const isJdSportsUk = isJdSportsUkOrder(orderDetail);

  const isCancelButtonHiddenForEmeaPartner =
    EmeaPartnersNotAllowingCancelOnReturn.includes(emeaPartner) || isJdSportsUk;

  const isKROrJPStoreOrder =
    [KOREA, JAPAN].includes(region) && orderDetail.orderClassification === 'STORE';

  const hasCancelPermissions =
    !isGlobalStoreOrder &&
    ((hasPermission(CancelOrders) && !isKROrJPStoreOrder) ||
      (hasPermission(ReturnAndCancelO2O) && isKROrJPStoreOrder && isDSIS));

  const hasReturnPermission =
    !isGlobalStoreOrder &&
    ((hasPermission(CreateReturn) && !isKROrJPStoreOrder) ||
      (hasPermission(ReturnAndCancelO2O) && isKROrJPStoreOrder && isDSIS));

  const inspectReinspectReason = orderDetail?.omoboFlags?.isReInspectable
    ? ReinspectReturn
    : InspectReturn;

  let buttons = {
    discountShipping: (
      <HasPermission permission={DiscountShipping}>
        {!isGlobalStoreOrder && (
          <Button
            onClick={handleOpenDiscountShippingDialog}
            color='inherit'
            aria-label={DISCOUNT_SHIPPING.toLowerCase()}
            data-testid='discount-shipping-button'
            classes={{
              root: classes.button,
              disabled: classes.buttonDisabled,
            }}
            disabled={!orderDetail?.omoboFlags?.isDiscountShippingAllowed}>
            <ShippingIcon className={classes.buttonIcon} />
            {DISCOUNT_SHIPPING}
          </Button>
        )}
      </HasPermission>
    ),
    modify: (
      <HasPermission permission={ModifyPrice}>
        {!isGlobalStoreOrder && (
          <Button
            color='inherit'
            target='_blank'
            aria-label={MODIFY.toLowerCase()}
            onClick={handleModifyPriceDialog}
            data-testid='modify-button'
            classes={{
              root: classes.button,
              disabled: classes.buttonDisabled,
            }}
            disabled={!orderDetail?.orderHeaderKey || orderDetail?.pendingModification}>
            <ModifyIcon className={classes.buttonIcon} />
            {MODIFY}
          </Button>
        )}
      </HasPermission>
    ),
    cancel: (
      <>
        {hasCancelPermissions && (
          <Button
            color='inherit'
            classes={{
              root: classes.button,
              disabled: classes.buttonDisabled,
            }}
            onClick={handleOpenCancelDialog}
            aria-label={CANCEL_ITEMS.toLowerCase()}
            data-testid='cancel-button'
            disabled={!orderDetail?.omoboFlags?.isCancellable}>
            <CancelIcon className={classes.buttonIcon} />
            {CANCEL_ITEMS}
          </Button>
        )}
      </>
    ),
    createReturn: (
      <>
        {hasReturnPermission && !isBopisOrderForAustralia && (
          <Button
            color='inherit'
            classes={{
              root: classes.button,
              disabled: classes.buttonDisabled,
            }}
            onClick={handleOpenReturnDialog}
            disabled={!orderDetail?.omoboFlags?.isReturnable}
            aria-label={CREATE_RETURN.toLowerCase()}
            tabIndex={0}
            data-testid='create-return-button'>
            <CreateReturnIcon className={classes.buttonIcon} />
            {CREATE_RETURN}
          </Button>
        )}
      </>
    ),
    exchange: (
      <HasPermission permission={ExchangeProduct}>
        {!isGlobalStoreOrder && !isOrderStoreOrderReturn && (
          <Button
            color='inherit'
            aria-label={EXCHANGE.toLowerCase()}
            disabled={!orderDetail?.omoboFlags?.isExchangeable}
            classes={{
              root: classes.button,
              disabled: classes.buttonDisabled,
            }}
            onClick={handleExchangeDialog}
            data-testid='exchange-button'>
            <ExchangeIcon className={classes.buttonIcon} />
            {EXCHANGE}
          </Button>
        )}
      </HasPermission>
    ),
    inspectReinspect: (
      <HasPermission permission={inspectReinspectReason}>
        <Button
          color='inherit'
          onClick={handleOpenInspectDialog}
          aria-label={INSPECT.toLowerCase()}
          disabled={!orderDetail?.omoboFlags?.isInspectable}
          classes={{
            root: classes.button,
            disabled: classes.buttonDisabled,
          }}
          data-testid='inspection-button'>
          <InspectIcon className={classes.buttonIcon} />
          {orderDetail?.omoboFlags?.isReInspectable ? REINSPECT : INSPECT}
        </Button>
      </HasPermission>
    ),
  };

  // top bar data is set initially with the correct buttons for a basic sales order
  // NIKEXA sales orders should not have any buttons
  let topBarData = (
    <>
      {buttons.discountShipping}
      {region !== KOREA && buttons.modify}
      {buttons.cancel}
      {buttons.createReturn}
    </>
  );

  // conditional logic to display the top bar buttons
  /* 
    Sets topBarData to null if order is from a partner in a non-EMEA region, 
    when the partner is NOT jdsports (because jdsports is in EMEA & other regions).
  */
  if (
    Partners[channel] &&
    channel !== 'jdsports.digital.web' &&
    region !== Geo.EUROPE &&
    !EmeaPartnersMasqueradingAsGeos.has(region)
  ) {
    topBarData = null;
  } else if (emeaPartner) {
    if (
      isJdSportsUk || // Returns not allowed for JD Sports partner orders in UK
      // Returns not allowed for these partners
      emeaPartner === 'footlocker.digital.web' ||
      emeaPartner === 'sportamore.digital.web' ||
      emeaPartner === 'footlockeremea.digital.web' ||
      emeaPartner === 'perry.digital.web'
    ) {
      topBarData = null;
    } else if (orderDetail.orderType === 'RETURN_ORDER') {
      topBarData = (
        <>
          {!isCancelButtonHiddenForEmeaPartner && buttons.cancel}
          {EmeaPartnersAllowingInspections.has(emeaPartner) && buttons.inspectReinspect}
        </>
      );
    } else {
      // not a return order, so display the return button only
      topBarData = <>{buttons.createReturn}</>;
    }
  } else if (
    orderDetail.orderClassification === 'STORE' &&
    orderDetail.orderType !== 'RETURN_ORDER'
  ) {
    /* 
      Korea or Japan DSIS order - Display cancel and return buttons
      Non-Korea non-Japan store order (sales) - Display return button
    */
    topBarData = (
      <>
        {[KOREA, JAPAN].includes(region) && buttons.cancel}
        {buttons.createReturn}
      </>
    );
    // not a sales order, must be a return order
  } else if (orderDetail.orderType !== 'SALES_ORDER') {
    // don't display exchange button on bopis order
    if (orderDetail?.omoboFlags?.isBopisReturn) {
      topBarData = (
        <>
          {buttons.cancel}
          {buttons.inspectReinspect}
        </>
      );
    } else {
      // standard return order
      topBarData = (
        <>
          {buttons.cancel}
          {region !== KOREA && region !== CHINA && buttons.exchange}
          {buttons.inspectReinspect}
        </>
      );
    }
  } else if (orderDetail?.parentReturnOrder) {
    // these buttons are for an exchange order
    topBarData = (
      <>
        {region !== KOREA && buttons.modify}
        {buttons.cancel}
        {buttons.createReturn}
      </>
    );
  }

  return (
    <>
      <TopBarContainer>
        {topBarData}
        <Button
          aria-label={`${ORDER_SUMMARY.toLowerCase()}`}
          data-testid='info-drawer-button'
          aria-expanded={infoDrawerOpen}
          color='inherit'
          onClick={handleInfoDrawer}
          classes={{
            root: classes.button,
            disabled: classes.buttonDisabled,
          }}>
          {infoDrawerOpen ? (
            <ChevronRightIcon />
          ) : (
            <>
              <InfoIcon className={classes.buttonIcon} /> {ORDER_SUMMARY}
            </>
          )}
        </Button>
      </TopBarContainer>
      <DialogContainer />
    </>
  );
}

TopBar.propTypes = {
  handleInfoDrawer: PropTypes.func,
  infoDrawerOpen: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  button: {
    'margin': theme.spacing(1),
    '&$buttonDisabled': {
      color: theme.palette.grey[500],
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  buttonDisabled: {},
}));
