import gql from 'graphql-tag';

const RETAIL_STORE_PAYMENT_DETAILS_QUERY = gql`
  query getRetailStorePaymentDetails($orderNumber: String!) {
    retailStorePaymentDetails(orderNumber: $orderNumber) {
      orderId
      orderDate
      tenderTypes {
        tenderUUID
        tenderType
        subTenderType
        originalAmount {
          value
          currencyCode
        }
        authorizationCode
        processorReferenceNumber
        truncatedAccountNumber
        bin
        fundingSource
        paymentMethod
        remainingAmount {
          value
          currencyCode
        }
        nikeReferenceNumber
        merchantAccount
        authorizationDateTime
        paymentProvider
        isReferenceRefundSupported
      }
    }
  }
`;

export default RETAIL_STORE_PAYMENT_DETAILS_QUERY;
