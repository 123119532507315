import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import mapValues from 'lodash/mapValues';
import { NikeI18nContext } from '@nike/i18n-react';

/** Material UI */
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import PlayArrow from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import Typography from '@material-ui/core/Typography';

/** Local */
import translations from './paymentMethodMods.i18n';
import { SuspendOrActivatePayment } from '../../../../../constants/permissions.const';
import usePaymentMods, { GenerateOrderNotes } from '../../../../../hooks/usePaymentMods';
import HasPermission from '../../../../shared/hasPermission';
import SimpleDialog from '../../../../shared/simpleDialog';

/**
 * Payment Method Modifications component
 * This component is responsible for handling the activation and suspension of
 * payment methods on an order; it renders the activate/suspend buttons as well
 * as a simple confirmation dialog for activating a suspended CC when another CC
 * is already active.
 */
const PaymentMethodMods = ({
  activeCC,
  cardType,
  index,
  isPaymentMethodActive,
  last4Digits,
  orderNumber,
  orderType,
  parentReturnOrder,
  partner,
  paymentId,
  paymentMethod,
  region,
  updatePaymentDetails,
  isStoreOrder,
}) => {
  const { i18nString } = useContext(NikeI18nContext);
  const {
    ACTIVATE_PAYMENT,
    ACTIVATE_PAYMENT_METHOD,
    CONFIRM_ACTIVATING_CARD,
    CONFIRM_ACTIVATING_METHOD,
    CONFIRM_SUSPENDING_METHOD,
    SUSPEND_PAYMENT,
    SUSPEND_PAYMENT_METHOD,
    SURE_TO_CONTINUE,
    YES,
  } = mapValues(translations, i18nString);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const classes = useStyles();
  const {
    handleActivatePaymentMethod,
    activatePaymentLoading,
    suspendPaymentLoading,
    handleSuspendPaymentMethod,
  } = usePaymentMods(updatePaymentDetails);

  /**
   * This useEffect changes the messaging in the activate/suspend payment
   * method confirmation modal based on whether or not the payment method
   * in question is currently active or suspended.
   */
  useEffect(() => {
    if (!isPaymentMethodActive) {
      setModalMessage(
        activeCC?.paymentId && paymentMethod === 'CreditCard'
          ? `${CONFIRM_ACTIVATING_CARD} ${activeCC?.accountNumber?.slice(-4)}.`
          : `${CONFIRM_ACTIVATING_METHOD} ${last4Digits}.`
      );
    } else {
      setModalMessage(`${CONFIRM_SUSPENDING_METHOD} ${last4Digits}.`);
    }
  }, [isPaymentMethodActive, activeCC, paymentMethod]);

  const CSR_PAYMENT_MODIFY = 'CSR Payment Modify';
  const standardPaymentTypeMessage =
    paymentMethod === 'CreditCard'
      ? `${cardType} ${last4Digits}`
      : `${paymentMethod} ${last4Digits}`;

  const disableActiveOrderNotes = GenerateOrderNotes(
    CSR_PAYMENT_MODIFY,
    `Suspended ${activeCC?.cardType} ${activeCC?.accountNumber?.slice(-4)}`
  );
  const standardActivateOrderNotes = GenerateOrderNotes(
    CSR_PAYMENT_MODIFY,
    `Activated ${standardPaymentTypeMessage}`
  );
  const standardSuspendOrderNotes = GenerateOrderNotes(
    CSR_PAYMENT_MODIFY,
    `Suspended ${standardPaymentTypeMessage}`
  );

  return (
    <Box data-testid={`pmt-status-update-buttons-${index}`}>
      {/* only display a button if we have a non-exchange, 
                  non-partner sales order and region is not Korea, China or Global */}
      {!parentReturnOrder && !partner && !['NIKEKR', 'NIKECN', 'NIKEGS'].includes(region) && (
        <>
          <SimpleDialog
            isOpen={modalIsOpen}
            setIsOpen={setModalIsOpen}
            title={!isPaymentMethodActive ? ACTIVATE_PAYMENT_METHOD : SUSPEND_PAYMENT_METHOD}
            content={
              <>
                <Typography display='block'>{modalMessage}</Typography>
                <br />
                <Typography display='block'>{SURE_TO_CONTINUE}</Typography>
              </>
            }
            confirmOptions={{
              label: YES,
              action: !isPaymentMethodActive
                ? () =>
                    handleActivatePaymentMethod(
                      activeCC,
                      paymentMethod,
                      orderNumber,
                      paymentId,
                      disableActiveOrderNotes,
                      standardActivateOrderNotes
                    )
                : () =>
                    handleSuspendPaymentMethod(
                      orderNumber,
                      paymentId,
                      standardSuspendOrderNotes,
                      isStoreOrder ? 'RETAIL' : 'DIGITAL'
                    ),
            }}
            testId={!isPaymentMethodActive ? 'activate-payment-modal' : 'suspend-payment-modal'}
          />
          <HasPermission permission={SuspendOrActivatePayment}>
            {/* ensure we display the correct button for Payment Suspension/Activation */}
            {!isPaymentMethodActive ? (
              <Button
                variant='outlined'
                aria-label={ACTIVATE_PAYMENT.toLowerCase()}
                className={classes.button2}
                disabled={activatePaymentLoading}
                onClick={() => setModalIsOpen(true)}
                data-testid='activate-payment-button'
                startIcon={<PlayArrow />}>
                {ACTIVATE_PAYMENT}
              </Button>
            ) : (
              <Button
                variant='outlined'
                aria-label={SUSPEND_PAYMENT.toLowerCase()}
                className={classes.button2}
                disabled={suspendPaymentLoading}
                onClick={() => setModalIsOpen(true)}
                data-testid='suspend-payment-button'
                startIcon={<PauseIcon />}>
                {SUSPEND_PAYMENT}
              </Button>
            )}
          </HasPermission>
        </>
      )}
    </Box>
  );
};

PaymentMethodMods.propTypes = {
  activeCC: PropTypes.shape({
    accountNumber: PropTypes.string,
    paymentId: PropTypes.string,
    cardType: PropTypes.string,
  }),
  cardType: PropTypes.string,
  index: PropTypes.number.isRequired,
  isPaymentMethodActive: PropTypes.bool,
  last4Digits: PropTypes.string,
  orderNumber: PropTypes.string,
  orderType: PropTypes.string,
  parentReturnOrder: PropTypes.string,
  partner: PropTypes.string,
  paymentId: PropTypes.string,
  paymentMethod: PropTypes.string,
  region: PropTypes.string,
  updatePaymentDetails: PropTypes.func.isRequired,
  isStoreOrder: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  button2: {
    margin: `${theme.spacing(1.25)}px 0`,
    borderRadius: '24px',
    borderWidth: '1.5px',
    fontSize: '1rem',
    lineHeight: 1.5,
    textTransform: 'unset',
    padding: `${theme.spacing(0.75)}px
      ${theme.spacing(2.5)}px
      ${theme.spacing(0.75)}px
      ${theme.spacing(1.5)}px`,
  },
}));

export default PaymentMethodMods;
