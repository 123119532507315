/** React/Utils */
import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

/** Material UI */
import { makeStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import LinkIcon from '@material-ui/icons/Link';

/** Local */
import translations from './member.i18n';
import SidebarItem from './sidebarItem';
import IDENTITY_USER_QUERY from '../../../mutations/identityUser.mutation';
import OrderContext from '../../../store/contexts/orderContext';
import { ConsumerContext } from '../../../store/contexts/consumerContext';
import useSnacks from '../../../hooks/useSnacks';
import { OrderDetailVersion, UserType } from '../../../constants/order.const';
import { getBillingName, getBillingEmail, getBillingPhone } from '../../../utils/order';
import consumerActions from '../../../store/actions/consumerActions';
import { setPersonNames } from '../../../utils/orderDetailPersonNames';
import config from '../../../utils/config';
import HasPermission from '../../shared/hasPermission';
import { EditCommunicationMethod, LinkOrUnlinkOrder } from '../../../constants/permissions.const';
import { Partners } from '../../../constants/origin.const';
import useMemoTranslations from '../../../hooks/useMemoTranslations';
import NewTabButton from '../../shared/newTabButton';
import useHasPermission from '../../../hooks/useHasPermission';
import { ViewMemberProfile } from '../../../constants/permissions.const';

import LinkMemberProfileModal from './linkMemberDialog/linkMemberProfile';
import UnlinkMemberProfileModal from './unlinkMemberDialog/unlinkMemberProfile';
import Geo from '../../../constants/geos.const';
import UpdateEmail from './updateEmail/updateEmail';
const Member = ({ orderChangeCallback }) => {
  const {
    ERROR,
    GUEST,
    ORDERED_BY,
    BRAND_MEMBER,
    SWOOSH_MEMBER,
    ARIA_PROFILE_INFO,
    UNLINK_PROFILE,
    LINK_PROFILE,
    ARIA_MEMBER_PROFILE_LINK,
  } = useMemoTranslations(translations);
  const { setError } = useSnacks();
  const classes = useStyles();
  const [orderDetail] = useContext(OrderContext);
  const [, consumerDispatch] = useContext(ConsumerContext);
  const {
    setConsumer,
    setIdentityConsumerType,
    setIdentityConsumerEmail,
    setIdentityConsumerPhone,
    setIdentityConsumerName,
    setIdentityKanaFirstName,
    setIdentityKanaLastName,
  } = consumerActions;

  const cspUrl = `${config.cspUrl}csr/cloud/orderDetail?orderNumber=${orderDetail?.orderNumber}&orderHeaderKey=`;

  const [orderDetailVersion, setOrderDetailVersion] = useState();
  const [consumerType, setConsumerType] = useState('');
  const [billingName, setBillingName] = useState('');
  const [billingEmail, setBillingEmail] = useState('');
  const [billingPhone, setBillingPhone] = useState('');
  const [idnUser, setIdnUser] = useState();
  const [linkProfileModalOpen, setLinkProfileModalOpen] = useState(false);
  const [unlinkProfileModalOpen, setUnlinkProfileModalOpen] = useState(false);

  const { hasPermission } = useHasPermission();

  const toggleLinkModal = () => {
    setLinkProfileModalOpen(!linkProfileModalOpen);
  };

  const toggleUnlinkModal = () => {
    setUnlinkProfileModalOpen(!unlinkProfileModalOpen);
  };

  const [getIdentityUser] = useMutation(IDENTITY_USER_QUERY, {
    // If the error is 404, consumerType is GUEST, otherwise show error in SNACK.
    onError: (err) => {
      if (err.message && err.message.includes('error: 404')) {
        if (orderDetailVersion === OrderDetailVersion.V1) {
          setConsumerType(UserType.GUEST);
        }
      } else {
        setError(`${ERROR}`);
      }
    },
    // On completed, if orderDetail is V1, set the consumerType. (If V2, we already set it.)
    onCompleted(idnData) {
      consumerDispatch(setIdentityConsumerName(idnData?.identityUser?.name?.latin));
      consumerDispatch(setIdentityConsumerEmail(idnData?.identityUser?.contact?.email?.address));
      consumerDispatch(
        setIdentityConsumerPhone(idnData?.identityUser?.contact?.sms?.verifiedNumber)
      );

      setIdnUser(idnData.identityUser);
      // Always rely on idn data for consumer type as user type in order details is not reliable
      setConsumerType(
        idnData.identityUser.userType === 'SWOOSH' || idnData.identityUser.entitlements?.swoosh
          ? UserType.SWOOSH
          : UserType.BRAND
      );
      if (orderDetailVersion === OrderDetailVersion.V1) {
        // The presence of a upmId tells us this consumer is either SWOOSH or BRAND, not GUEST.
        if (idnData?.identityUser?.upmId) {
          // AS per existing CSP logic, if IDN type is not SWOOSH then set consumerType to BRAND.
          setConsumerType(
            idnData.identityUser.userType === 'SWOOSH' || idnData.identityUser.entitlements?.swoosh
              ? UserType.SWOOSH
              : UserType.BRAND
          );
          consumerDispatch(setIdentityKanaFirstName(idnData.identityUser.name?.kana?.given));
          consumerDispatch(setIdentityKanaLastName(idnData.identityUser.name?.kana?.family));
        } else {
          // The data returned from IDN was empty, so consumerType is GUEST.
          setConsumerType(UserType.GUEST);
        }
      }
    },
    notifyOnNetworkStatusChange: true,
  });

  const partner = orderDetail?.channel ? Partners[orderDetail?.channel] : '';

  /**
   * This useEffect calls IDN (provided there is a customerProfileReference) and sets the
   * consumerName, consumerEmail, and consumerType.
   */
  useEffect(() => {
    const billingName = getBillingName(orderDetail);
    // set billing name, email and phone
    setBillingName(billingName);
    setBillingEmail(getBillingEmail(orderDetail));
    setBillingPhone(getBillingPhone(orderDetail));

    // Set the orderDetailVersion based on the presence of the userType.
    if (orderDetail.userInfo?.userType) {
      setOrderDetailVersion(OrderDetailVersion.V2);
      // If V2, consumerType is set from orderDetail.
      setConsumerType(orderDetail.userInfo.userType);
    } else {
      setOrderDetailVersion(OrderDetailVersion.V1);
    }

    if (orderDetail.customerProfileReference) {
      consumerDispatch(setConsumer(orderDetail.customerProfileReference));
      // Regardless of orderVersion, call IDN if customerProfileReference is present.
      getIdentityUser({
        variables: {
          id: orderDetail.customerProfileReference,
          input: {
            fields: [
              'name.latin.given',
              'name.latin.middle',
              'name.latin.family',
              'name.kana.given',
              'name.kana.family',
              'contact.email.address',
              'contact.sms.verifiedNumber',
              'upmId',
              'userType',
              'entitlements.swoosh',
            ],
          },
        },
      });
    } else {
      // customerProfileReference is not present, so consumerType is GUEST.
      setConsumerType(UserType.GUEST);
    }
  }, [orderDetail, orderDetail.customerProfileReference]);

  useEffect(() => {
    consumerDispatch(setIdentityConsumerType(consumerType));
  }, [consumerType]);

  /**
   * This useEffect triggers the setPersonNames function to cross-populate the name fields in the
   * orderDetail and also to set the kana names from the idnUser, if available.
   */
  useEffect(() => {
    setPersonNames(orderDetail, idnUser);
  }, [idnUser]);

  /**
   * Gets the consumerMemberInfo for display.
   *
   * @returns {Object} the consumerMemberInfo for display
   */
  const displayConsumerMemberInfo = () => {
    let consumerTypeLabel = '';
    if (consumerType === UserType.SWOOSH) {
      consumerTypeLabel = SWOOSH_MEMBER;
    } else if (consumerType === UserType.BRAND) {
      consumerTypeLabel = BRAND_MEMBER;
    }
    const allowedLinkUnlinkGeos = [
      Geo.JAPAN,
      Geo.US,
      Geo.EUROPE,
      Geo.KOREA,
      Geo.NIKEGS,
      Geo.NIKEXA,
      Geo.AUSTRALIA,
    ];

    const isShowLinkUnlinkButton =
      !partner &&
      orderDetail.omsRegionReference &&
      allowedLinkUnlinkGeos.includes(orderDetail.omsRegionReference.toUpperCase());

    return (
      <>
        <span data-testid='consumer-type'>
          {consumerTypeLabel ? consumerTypeLabel + ' ' : GUEST}
          {consumerTypeLabel &&
            (hasPermission(ViewMemberProfile) ? (
              <NewTabButton
                label={orderDetail.customerProfileReference}
                href={`${config.memberProfileUrl}/${orderDetail.customerProfileReference}/details`}
                data-testid='member_profile_link'
                ariaLabel={ARIA_MEMBER_PROFILE_LINK}
              />
            ) : (
              // if no memberProfile permissions, no member profile app link (same as before)
              <Link>{orderDetail.customerProfileReference}</Link>
            ))}
        </span>

        {isShowLinkUnlinkButton && (
          <HasPermission permission={LinkOrUnlinkOrder}>
            {/* cloud-based link\unlink functionality should be displayed only for
            NIKEGS, NIKEEUROPE, US, JAPAN and NIKEXA regions. 
            For rest of regions we will display link to CSP
             */}
            {orderDetail.omsRegionReference === Geo.NIKEGS ||
            orderDetail.omsRegionReference === Geo.NIKEXA ||
            orderDetail.omsRegionReference === Geo.US ||
            orderDetail.omsRegionReference === Geo.JAPAN ||
            orderDetail.omsRegionReference === Geo.EUROPE ? (
              orderDetail.customerProfileReference ? (
                <Button
                  onClick={setUnlinkProfileModalOpen}
                  aria-label={UNLINK_PROFILE.toLowerCase()}
                  data-testid='unlink-profile-button'
                  className={classes.linkProfileButton}>
                  <LinkOffIcon className={classes.buttonIcon} />
                  {UNLINK_PROFILE}
                </Button>
              ) : (
                <Button
                  onClick={setLinkProfileModalOpen}
                  aria-label={LINK_PROFILE.toLowerCase()}
                  data-testid='link-profile-button'
                  className={classes.linkProfileButton}>
                  <LinkIcon className={classes.buttonIcon} />
                  {LINK_PROFILE}
                </Button>
              )
            ) : (
              <>
                <Button
                  target='_blank'
                  href={cspUrl}
                  aria-label={
                    consumerTypeLabel ? UNLINK_PROFILE.toLowerCase() : LINK_PROFILE.toLowerCase()
                  }
                  data-testid={consumerTypeLabel ? 'unlink-profile-button' : 'link-profile-button'}
                  className={classes.linkProfileButton}>
                  {consumerTypeLabel ? (
                    <LinkOffIcon className={classes.buttonIcon} />
                  ) : (
                    <LinkIcon className={classes.buttonIcon} />
                  )}
                  {consumerTypeLabel ? UNLINK_PROFILE : LINK_PROFILE}
                </Button>
              </>
            )}
          </HasPermission>
        )}
      </>
    );
  };

  const onLinkSuccess = () => {
    orderChangeCallback();
    setLinkProfileModalOpen(false);
  };

  const onUnlinkSuccess = () => {
    orderChangeCallback();
    setUnlinkProfileModalOpen(false);
  };

  const isShowEditEmailButton =
    !partner &&
    billingEmail &&
    orderDetail.omsRegionReference !== 'NIKEKR' &&
    orderDetail.omsRegionReference !== 'NIKECN' &&
    hasPermission(EditCommunicationMethod);

  return (
    <SidebarItem dataTestId='orderedBySection' title={ORDERED_BY} icon={<AccountCircleIcon />}>
      <Typography
        aria-label={ARIA_PROFILE_INFO}
        className={classes.addressTypography}
        component='address'>
        <span data-testid='billing-name'>{billingName}</span>
        {isShowEditEmailButton ? (
          <UpdateEmail
            emailValue={billingEmail}
            contactName={billingName}
            orderNumber={orderDetail?.orderNumber}
            onSuccess={orderChangeCallback}
          />
        ) : (
          <span data-testid='billing-email'>{billingEmail ? `\n${billingEmail}` : null}</span>
        )}
        <span data-testid='billing-phone'>{billingPhone ? `\n${billingPhone}` : ''}</span>
        {'\n'}
        {displayConsumerMemberInfo()}
      </Typography>
      <HasPermission permission={LinkOrUnlinkOrder}>
        <LinkMemberProfileModal
          isOpen={linkProfileModalOpen}
          handleClose={toggleLinkModal}
          orderNumber={orderDetail?.orderNumber}
          onSuccess={onLinkSuccess}
        />
      </HasPermission>
      <HasPermission permission={LinkOrUnlinkOrder}>
        <UnlinkMemberProfileModal
          isOpen={unlinkProfileModalOpen}
          handleClose={toggleUnlinkModal}
          orderNumber={orderDetail?.orderNumber}
          customerProfileReference={orderDetail.customerProfileReference}
          onSuccess={onUnlinkSuccess}
        />
      </HasPermission>
    </SidebarItem>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.dark,
  },
  linkProfileButton: {
    color: theme.palette.primary.dark,
  },
  buttonIcon: {
    marginRight: theme.spacing(0.5),
  },
  addressTypography: {
    whiteSpace: 'pre-line',
    lineHeight: 2,
  },
}));

export default Member;
